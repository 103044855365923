<template>
  <div class="radio-group">
    <div class="btn-group-item check">
      <input
        type="radio"
        class="btn-check"
        :id="`good_${fieldForm.id}`"
        :name="`field_${fieldForm.id}`"
        v-model="fieldValue.value"
        value="GOOD"
        :disabled="disabled"
      />
      <label
        class="btn btn-outline-primary"
        :for="`good_${fieldForm.id}`"
        @click="
          () => {
            showActionModal = true;
          }
        "
      >
        <i class="far fa-check-circle"></i>
      </label>
    </div>
    <div class="btn-group-item check triangle">
      <input
        type="radio"
        class="btn-check"
        :id="`warning_${fieldForm.id}`"
        :name="`field_${fieldForm.id}`"
        v-model="fieldValue.value"
        value="WARNING"
        :disabled="disabled"
      />
      <label
        class="btn btn-outline-primary"
        :for="`warning_${fieldForm.id}`"
        @click="
          () => {
            if (disabled) {
              if (fieldValue === 'WARNING') {
                showActionModal = true;
              }
            } else {
              showActionModal = true;
            }
          }
        "
      >
        <i class="far fa-exclamation-triangle"></i>
      </label>
    </div>
    <div class="btn-group-item times">
      <input
        type="radio"
        class="btn-check"
        :id="`bad_${fieldForm.id}`"
        :name="`field_${fieldForm.id}`"
        v-model="fieldValue.value"
        value="BAD"
        :disabled="disabled"
      />
      <label
        class="btn btn-outline-primary"
        :for="`bad_${fieldForm.id}`"
        @click="
          () => {
            if (disabled) {
              if (fieldValue === 'BAD') {
                showActionModal = true;
              }
            } else {
              showActionModal = true;
            }
          }
        "
      >
        <i class="far fa-times-circle"></i>
      </label>
    </div>

    <modal :show.sync="showActionModal" modal-classes="battery-status">
      <template slot="header">
        <h5 class="modal-title" id="inspection-message">
          <i class="far fa-battery-bolt"></i>
          {{ $t("INSPECTIONS.BATTERY_CHARGE") }}
        </h5>
      </template>
      <div class="battery-status-wrapper">
        <base-input v-model="fieldValue.charge" :disabled="disabled">
        </base-input>

        <div class="form-group-separator" :disabled="disabled">/</div>

        <base-input v-model="fieldValue.total_charge" :disabled="disabled">
        </base-input>

        <base-input
          class="units"
          :label="$t('INSPECTIONS.UNIT')"
          :placeholder="$t('INSPECTIONS.UNIT')"
          :disabled="disabled"
        >
          <el-select
            v-model="fieldValue.unit"
            :placeholder="$t('INSPECTIONS.UNIT')"
            :disabled="disabled"
          >
            <el-option class="" value="AMPS">
              {{ $t("INSPECTIONS.AMPS") }}
            </el-option>
            <el-option class="" value="VOLTS">
              {{ $t("INSPECTIONS.VOLTS") }}
            </el-option>
          </el-select>
        </base-input>
      </div>
      <template slot="footer">
        <base-button
          v-if="disabled"
          type="secondary"
          class="btn close"
          @click="showActionModal = false"
        >
          {{ $t("COMMON.CLOSE") }}
        </base-button>

        <base-button
          v-if="!disabled"
          type="primary"
          class="btn save"
          @click="saveValue"
        >
          {{ $t("COMMON.SUBMIT") }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { uniqueId } from "lodash";
import { Button, Select, Option } from "element-ui";

export default {
  name: "battery-state-field-widget",

  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: ["fieldForm", "defaultValue", "disabled"],

  data() {
    const refKey = uniqueId([]);
    return {
      fieldValue: { ...this.defaultValue },
      showActionModal: false,
      refKey: refKey,
    };
  },

  computed: {},

  created() {},

  methods: {
    saveValue() {
      this.$emit("change", {
        value: this.fieldValue.value,
        charge: this.fieldValue.charge,
        total_charge: this.fieldValue.total_charge,
        unit: this.fieldValue.unit,
      });
      this.showActionModal = false;
    },
  },

  mounted() {},

  watch: {
    defaultValue() {
      this.fieldValue = { ...this.defaultValue };
    },
    defaultActionValue() {
      this.actionValue = this.defaultActionValue;
    },
  },
};
</script>
