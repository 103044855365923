<template>
  <div class="radio-group">
    <div class="btn-group-item ban" v-if="fieldForm.enable_na">
      <input
        type="radio"
        class="btn-check"
        :id="`not_applicable_${fieldForm.id}`"
        :name="`field_${fieldForm.id}`"
        v-model="fieldValue"
        value="NOT_APPLICABLE"
        :disabled="disabled"
      />
      <label
        class="btn btn-outline-primary"
        :for="`not_applicable_${fieldForm.id}`"
        @click="setNA"
      >
        N/A
      </label>
    </div>
    <div class="btn-group-item check">
      <input
        type="radio"
        class="btn-check"
        :id="`good_${fieldForm.id}`"
        :name="`field_${fieldForm.id}`"
        v-model="fieldValue"
        value="GOOD"
        :disabled="disabled"
      />
      <label
        @click="setGood"
        class="btn btn-outline-primary"
        :for="`good_${fieldForm.id}`"
      >
        <i class="far fa-check-circle"></i>
      </label>
    </div>
    <div class="btn-group-item check triangle">
      <input
        type="radio"
        class="btn-check"
        :id="`warning_${fieldForm.id}`"
        :name="`field_${fieldForm.id}`"
        v-model="fieldValue"
        value="WARNING"
        :disabled="disabled"
      />
      <label
        class="btn btn-outline-primary"
        :for="`warning_${fieldForm.id}`"
        @click="
          () => {
            if (!disableAction) {
              if (disabled) {
                if (fieldValue === 'WARNING') {
                  showActionModal = true;
                }
              } else {
                showActionModal = true;
              }
            }
          }
        "
      >
        <i class="far fa-exclamation-triangle"></i>
      </label>
    </div>
    <div class="btn-group-item times">
      <input
        type="radio"
        class="btn-check"
        :id="`bad_${fieldForm.id}`"
        :name="`field_${fieldForm.id}`"
        v-model="fieldValue"
        value="BAD"
        :disabled="disabled"
      />
      <label
        class="btn btn-outline-primary"
        :for="`bad_${fieldForm.id}`"
        @click="
          () => {
            if (!disableAction) {
              if (disabled) {
                if (fieldValue === 'BAD') {
                  showActionModal = true;
                }
              } else {
                showActionModal = true;
              }
            }
          }
        "
      >
        <i class="far fa-times-circle"></i>
      </label>
    </div>

    <action-comment-widget
      :showActionModal="showActionModal"
      :disabled="disabled"
      :actionValue="defaultActionValue"
      :fieldValue="fieldValue"
      :commentValue="commentValue"
      @actionCanceled="cancelAction"
      @actionChanged="changeAction"
    />
  </div>
</template>

<script>
import { uniqueId, isEqual } from "lodash";
import { INSPECTION_ACTION_NO_ACTION } from "@/constants/inspections";
import ActionCommentWidget from "./ActionCommentWidget.vue";

import { Button } from "element-ui";

export default {
  name: "state-field-widget",

  components: {
    [Button.name]: Button,
    ActionCommentWidget,
  },

  props: [
    "fieldForm",
    "defaultValue",
    "defaultActionValue",
    "disabled",
    "disableAction",
    "commentValue",
  ],

  data() {
    const refKey = uniqueId([]);
    return {
      fieldValue: this.defaultValue,
      showActionModal: false,
      refKey: refKey,
    };
  },

  computed: {},

  created() {},

  methods: {
    changeAction(action, comment) {
      this.showActionModal = false;
      this.$emit("change", this.fieldValue);
      this.$emit("actionChanged", action, comment);
    },

    setGood(action, comment) {
      this.$emit("change", "GOOD");
      this.$emit("actionChanged", INSPECTION_ACTION_NO_ACTION, null);
    },

    setNA(action, comment) {
      this.$emit("change", "NOT_APPLICABLE");
      this.$emit("actionChanged", INSPECTION_ACTION_NO_ACTION, null);
    },

    cancelAction() {
      this.fieldValue = this.defaultValue;
      this.showActionModal = false;
    },
  },

  mounted() {},

  watch: {
    defaultValue() {
      this.fieldValue = this.defaultValue;
    },
  },
};
</script>
