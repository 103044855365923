<template>
  <div
    v-if="inspectionForm"
    class="container-fluid inspection-global"
    style="margin-bottom: 1000px"
  >
    <base-button
      class="my-2 mx-0"
      type="info"
      icon
      size="sm"
      @click="returnToInspectionForm()"
      :title="$t('INSPECTION_FORMS.RETURN_TO_INSPECTION_FORM')"
    >
      <span class="btn-inner--icon">
        {{ $t("INSPECTION_FORMS.RETURN_TO_INSPECTION_FORM") }}
      </span>
    </base-button>
    <div class="inspection-form-vehicle-parameters my-4 row">
      <div class="col-6">
        <base-input
          :label="`${$t('VEHICLES.SEARCH_BY_VIN')}`"
          :placeholder="$t('VEHICLES.SEARCH_BY_VIN')"
        >
          <vehicle-api-by-vin-selector
            @vehicleSelected="vehicleSelected"
            clearable
          />
        </base-input>
      </div>
      <div class="col-6">
        <base-input
          :label="`${$t('INSPECTION_FORMS.VEHICLE_PRICE')}  ${
            noteRange
              ? ` : ${noteRange.min_value} - ${noteRange.max_value} (min ${noteRange.note})`
              : ``
          } `"
          :placeholder="`${$t('INSPECTION_FORMS.VEHICLE_PRICE')}`"
          inputGroupClasses="mx-0 mb-0"
          labelClasses="form-control-label mx-0 ml-0"
          v-model="vehiclePrice"
        >
        </base-input>
      </div>
      <div class="col-12 row mx-0 px-0">
        <div
          class="col-3"
          v-for="field in Object.keys(multipliersModel)"
          :key="field"
        >
          <base-input
            :label="
              $t(`INSPECTION_FORMS.MULTIPLIER_FIELD_${field.toUpperCase()}`)
            "
            :placeholder="
              $t(`INSPECTION_FORMS.MULTIPLIER_FIELD_${field.toUpperCase()}`)
            "
            inputGroupClasses="mx-0"
            labelClasses="form-control-label mx-0 my-0"
            v-model="multipliersModel[field]"
          >
          </base-input>
        </div>
      </div>
    </div>
    <div class="inspection-form-vehicle-parameters text-right pb-1">
      <base-button
        type="success"
        icon
        size="sm"
        @click="setAllGood()"
        :title="$t('INSPECTION_FORMS.SET_ALL_GOOD')"
      >
        <span class="btn-inner--icon">
          {{ $t("INSPECTION_FORMS.SET_ALL_GOOD") }}
        </span>
      </base-button>
      <base-button
        type="warning"
        icon
        size="sm"
        @click="setAllWarning()"
        :title="$t('INSPECTION_FORMS.SET_ALL_WARNING')"
      >
        <span class="btn-inner--icon">
          {{ $t("INSPECTION_FORMS.SET_ALL_WARNING") }}
        </span>
      </base-button>
      <base-button
        type="danger"
        icon
        size="sm"
        @click="setAllBad()"
        :title="$t('INSPECTION_FORMS.SET_ALL_BAD')"
      >
        <span class="btn-inner--icon">
          {{ $t("INSPECTION_FORMS.SET_ALL_BAD") }}
        </span>
      </base-button>
    </div>
    <div
      v-for="section of inspectionForm.inspectionFormSections"
      :key="section.id"
      class="col-12 row px-0 mx-0"
      style="border: 1px solid #000"
    >
      <div class="col-2">{{ section.name }}</div>
      <div class="col-10 px-0 mx-0">
        <div
          v-for="subSection of section.subSections"
          :key="subSection.id"
          class="col-12 row px-0 mx-0"
          style="border: 1px solid #000"
        >
          <div class="col-3">{{ subSection.name }}</div>
          <div class="col-9 px-0 mx-0">
            <div
              v-for="field of subSection.inspectionFormSectionFields"
              :key="field.id"
              class="col-12 row px-0 mx-0"
              style="border: 1px solid #000"
            >
              <div class="col-6">
                {{ field.name }}<br />
                <span style="font-size: 12px">
                  <icon-check :checked="field.impairs_proper_functionning" />
                  {{ $t("INSPECTION_FORMS.IMPAIRS_PROPER_FUNCTIONNING") }}
                  <br />
                  <icon-check :checked="field.major" />
                  {{ $t("INSPECTION_FORMS.MAJOR") }}
                </span>
              </div>
              <div class="col-3">
                <field-widget
                  :disableAction="true"
                  :fieldForm="field"
                  :defaultValue="inspectionModel[field.id].value"
                  :defaultActionValue="null"
                  @change="
                    (value) => {
                      fieldValueChanged(field, value);
                    }
                  "
                />
              </div>
              <div class="col-1">
                {{ inspectionModel[field.id].points }}
              </div>
              <div class="col-1">
                {{ inspectionModel[field.id].repairValue }}
              </div>
              <div class="col-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="inspection-form-demo-summary">
      <table>
        <tbody>
          <tr
            v-for="(multiplier, idx) of appliedMulipliers"
            :key="multiplier.id"
          >
            <td v-if="idx == 0" :rowspan="appliedMulipliers.length">
              {{ $t("INSPECTION_FORMS.MULTIPLIERS") }}
            </td>
            <td>
              <div class="my-2">
                {{
                  $t(
                    `INSPECTION_FORMS.MULTIPLIER_FIELD_${multiplier.field.toUpperCase()}`
                  )
                }}
                {{
                  $t(
                    `INSPECTION_FORMS.MULTIPLIER_OPERATION_${multiplier.operation.toUpperCase()}`
                  )
                }}
                {{ multiplier.value }}
              </div>
            </td>
            <td class="score">
              {{ multiplier.multiplier }} *
              {{
                $t(
                  `INSPECTION_FORMS.MULTIPLIER_TYPE_${multiplier.multiplier_type.toUpperCase()}`
                )
              }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("INSPECTION_FORMS.GLOBAL_POINTS") }}</td>
            <td>{{ getGlobalPoints() }}/{{ inspectionForm.maxPoints }}</td>
            <td class="score">{{ getGlobalPointsScore() }}%</td>
          </tr>
          <tr>
            <td>{{ $t("INSPECTION_FORMS.REPAIR_AMOUNT") }}</td>
            <td>{{ getGlobalRepairAmount() }}/{{ vehiclePrice }}</td>
            <td class="score">{{ getGlobalRepairAmountScore() }}%</td>
          </tr>
          <tr>
            <td>{{ $t("INSPECTION_FORMS.FINAL_SCORE") }}</td>
            <td colspan="2" class="score">{{ getGlobalScore() }}%</td>
          </tr>
          <tr>
            <td>{{ $t("INSPECTION_FORMS.ADJUSTED_FINAL_SCORE") }}</td>
            <td colspan="2" class="score">{{ getGlobalScoreAjusted() }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { Button, Table, TableColumn } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET } from "@/constants/inspectionForms";
import IconCheck from "@/components/IconCheck.vue";
import FieldWidget from "../InspectionManagement/partials/fieldsWidgets/FieldWidget.vue";
import {
  MULTIPLIER_OPERATION_CONTAINS,
  MULTIPLIER_OPERATION_EQUALS,
  MULTIPLIER_OPERATION_GREATER_THAN,
  MULTIPLIER_OPERATION_LESS_THAN,
  MULTIPLIER_TYPE_POINTS,
  MULTIPLIER_TYPE_REPAIR_VALUE,
} from "@/constants/inspectionFormMultipliers";
import VehicleApiByVinSelector from "@/components/VehicleApiByVinSelector.vue";

const MAJOR_PENALTY = 1;
const IMPAIRS_PROPER_FUNCTIONNING_PENALTY = 1;

export default {
  layout: "DashboardLayout",

  components: {
    FieldWidget,
    IconCheck,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    VehicleApiByVinSelector,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {
    multipliersModel: {
      handler: function (val) {
        this.refreshAppliedMultipliers();
        this.recalculateFields();
      },
      deep: true,
    },
    vehiclePrice: {
      handler: function (val) {
        const noteRange = this.inspectionForm.notes.find(
          (item) =>
            item.min_value <= this.vehiclePrice &&
            this.vehiclePrice <= item.max_value
        );
        if (noteRange) {
          this.noteRange = noteRange;
        } else {
          this.noteRange = null;
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      inspectionForm: null,
      inspectionModel: {},
      sections: {},
      subSections: {},
      vehiclePrice: 0,
      multipliersModel: {},
      appliedMulipliers: [],
      noteRange: null,
      vehicle: null,
    };
  },

  created() {
    this.get();

    window.addEventListener("scroll", function () {
      var fixedDiv = document.querySelector(".inspection-form-demo-summary");
      if (window.scrollY >= 100) {
        fixedDiv.classList.add("fixed");
      } else {
        fixedDiv.classList.remove("fixed");
      }
    });
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("inspectionForms/get", id);
        this.inspectionForm =
          this.$store.getters["inspectionForms/inspectionForm"];
        this.generateInspectionModel();
        this.generateMultipliersFields();
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    setAllGood() {
      const inspectionModelData = {};
      for (const section of this.inspectionForm.inspectionFormSections) {
        for (const subSection of section.subSections) {
          for (const field of subSection.inspectionFormSectionFields) {
            inspectionModelData[field.id] = {
              value: "GOOD",
              points: this.getPoints(field, "GOOD"),
              repairValue: this.getRepairAmount(field, "GOOD"),
            };
          }
        }
      }
      this.inspectionModel = inspectionModelData;
    },

    setAllWarning() {
      const inspectionModelData = {};
      for (const section of this.inspectionForm.inspectionFormSections) {
        for (const subSection of section.subSections) {
          for (const field of subSection.inspectionFormSectionFields) {
            inspectionModelData[field.id] = {
              value: "WARNING",
              points: this.getPoints(field, "WARNING"),
              repairValue: this.getRepairAmount(field, "WARNING"),
            };
          }
        }
      }
      this.inspectionModel = inspectionModelData;
    },

    setAllBad() {
      const inspectionModelData = {};
      for (const section of this.inspectionForm.inspectionFormSections) {
        for (const subSection of section.subSections) {
          for (const field of subSection.inspectionFormSectionFields) {
            inspectionModelData[field.id] = {
              value: "BAD",
              points: this.getPoints(field, "BAD"),
              repairValue: this.getRepairAmount(field, "BAD"),
            };
          }
        }
      }
      this.inspectionModel = inspectionModelData;
    },

    generateInspectionModel() {
      for (const section of this.inspectionForm.inspectionFormSections) {
        for (const subSection of section.subSections) {
          for (const field of subSection.inspectionFormSectionFields) {
            this.inspectionModel[field.id] = {
              points: field.maxPoints,
              value: this.getDefaultValue(field),
              repairValue: this.getRepairAmount(
                field,
                this.getDefaultValue(field)
              ),
            };
          }
        }
      }
    },

    getDefaultValue(field) {
      if (field.field_type === INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET) {
        return "GOOD";
      }
    },

    getRepairAmount(field, value) {
      let amount = 0;
      if (field.field_type === INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET) {
        switch (value) {
          case "GOOD":
            amount = 0;
            break;
          case "WARNING":
            amount = +field.mean_repair_value / 2;
            break;
          case "BAD":
            amount = +field.mean_repair_value;
            break;
        }
      }
      return this.applyRepairValueMultipliers(amount);
    },

    getPoints(field, value) {
      if (field.field_type === INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET) {
        for (const option of field.points_threshold) {
          if (option.option == value) {
            let points = +option.points;
            if (option.option == "BAD") {
              if (field.major) {
                points = points - MAJOR_PENALTY;
              }
              if (field.impairs_proper_functionning) {
                points = points - IMPAIRS_PROPER_FUNCTIONNING_PENALTY;
              }
              points = this.applyPointsMultipliers(points);
            }
            if (option.option == "WARNING") {
              points = this.applyPointsMultipliers(points);
            }
            return points;
          }
        }
      }
      return 0;
    },

    fieldValueChanged(field, value) {
      const inspectionModelData = { ...this.inspectionModel };
      inspectionModelData[field.id].value = value;
      inspectionModelData[field.id].points = this.getPoints(field, value);
      inspectionModelData[field.id].repairValue = this.getRepairAmount(
        field,
        value
      );
      this.inspectionModel = inspectionModelData;
    },

    getGlobalPoints() {
      return Object.values(this.inspectionModel).reduce(
        (accumulator, field) => {
          return accumulator + field.points;
        },
        0
      );
    },

    getGlobalRepairAmount() {
      return Object.values(this.inspectionModel).reduce(
        (accumulator, field) => {
          return accumulator + field.repairValue;
        },
        0
      );
    },

    getGlobalPointsScore() {
      return (
        (Object.values(this.inspectionModel).reduce((accumulator, field) => {
          return accumulator + field.points;
        }, 0) /
          this.inspectionForm.maxPoints) *
        100
      ).toFixed(2);
    },

    getGlobalRepairAmountScore() {
      if (this.vehiclePrice == 0) {
        return 0;
      }
      return Math.max(
        100 -
          (Object.values(this.inspectionModel).reduce((accumulator, field) => {
            return accumulator + field.repairValue;
          }, 0) /
            this.vehiclePrice) *
            100,
        50
      ).toFixed(2);
    },

    getGlobalScore() {
      const globalScore =
        (+this.getGlobalPointsScore() + +this.getGlobalRepairAmountScore()) / 2;

      return globalScore.toFixed(2);
    },

    getGlobalScoreAjusted() {
      let minValue = -Infinity;
      if (this.noteRange) {
        minValue = this.noteRange.note;
      }
      return Math.max(minValue, this.getGlobalScore()).toFixed(2);
    },

    generateMultipliersFields() {
      const multipliersModelData = {};
      for (const multiplier of this.inspectionForm.inspectionFormMultipliers) {
        multipliersModelData[multiplier.field] = null;
      }
      this.multipliersModel = multipliersModelData;
    },

    returnToInspectionForm() {
      this.$router.push(this.$objectViewRoute(this.inspectionForm));
    },

    refreshAppliedMultipliers() {
      const appliedMulipliersData = [];
      for (const multiplier of this.inspectionForm.inspectionFormMultipliers) {
        if (!this.multipliersModel[multiplier.field]) {
          continue;
        }

        if (multiplier.operation == MULTIPLIER_OPERATION_EQUALS) {
          if (
            this.multipliersModel[multiplier.field].toLowerCase() ==
            multiplier.value.toLowerCase()
          ) {
            appliedMulipliersData.push(multiplier);
          }
        } else if (multiplier.operation == MULTIPLIER_OPERATION_GREATER_THAN) {
          if (
            parseFloat(this.multipliersModel[multiplier.field]) >
            parseFloat(multiplier.value)
          ) {
            appliedMulipliersData.push(multiplier);
          }
        } else if (multiplier.operation == MULTIPLIER_OPERATION_LESS_THAN) {
          if (
            parseFloat(this.multipliersModel[multiplier.field]) <
            parseFloat(multiplier.value)
          ) {
            appliedMulipliersData.push(multiplier);
          }
        } else if (multiplier.operation == MULTIPLIER_OPERATION_CONTAINS) {
          if (
            this.multipliersModel[multiplier.field]
              .toLowerCase()
              .includes(multiplier.value.toLowerCase())
          ) {
            appliedMulipliersData.push(multiplier);
          }
        }
      }
      this.appliedMulipliers = appliedMulipliersData;
    },

    applyPointsMultipliers(points) {
      for (const multiplier of this.appliedMulipliers) {
        if (multiplier.multiplier_type === MULTIPLIER_TYPE_POINTS) {
          if (points > 0) {
            points = points * parseFloat(multiplier.multiplier);
          } else {
            points = points * (1 / parseFloat(multiplier.multiplier));
          }
        }
      }
      return points;
    },

    applyRepairValueMultipliers(amount) {
      for (const multiplier of this.appliedMulipliers) {
        if (multiplier.multiplier_type === MULTIPLIER_TYPE_REPAIR_VALUE) {
          amount = amount * parseFloat(multiplier.multiplier);
        }
      }
      return amount;
    },

    recalculateFields() {
      const inspectionModelData = {};
      for (const section of this.inspectionForm.inspectionFormSections) {
        for (const subSection of section.subSections) {
          for (const field of subSection.inspectionFormSectionFields) {
            const fieldModel = this.inspectionModel[field.id];
            inspectionModelData[field.id] = {
              value: fieldModel.value,
              points: this.getPoints(field, fieldModel.value),
              repairValue: this.getRepairAmount(field, fieldModel.value),
            };
          }
        }
      }
      this.inspectionModel = inspectionModelData;
    },

    async vehicleSelected(vehicle) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      const vehiclesPrices = await this.$store.dispatch(
        "vehicles/lookupWorstPriceByVin",
        vehicle.requested_vin
      );
      if (vehiclesPrices.length == 0) {
        await swal.fire({
          title: this.$t("INSPECTION_FORMS.NO_VEHCILE_FOUND_FOR_VIN"),
          icon: "success",
        });
      } else {
        const vehiclePrice = vehiclesPrices[0];
        if (vehiclePrice.price == 0) {
          await swal.fire({
            title: this.$t("INSPECTION_FORMS.NO_PRICE_FOUND_FOR_VEHICLE"),
            icon: "success",
          });
        }
        this.vehicle = vehicle;
        this.vehiclePrice = vehiclePrice.price;
        this.multipliersModel.make = this.vehicle.make;
        this.multipliersModel.model = this.vehicle.model;
        this.multipliersModel.style = this.vehicle.style;
        this.multipliersModel.year = this.vehicle.model_year;
        this.multipliersModel.odometer = this.vehicle.odometer;
      }
      swal.close();
    },
  },
};
</script>

<style lang="scss">
.inspection-form-demo-summary {
  position: fixed;
  bottom: 0;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  height: auto;
  min-height: 200px;
  width: auto;
  min-width: 600px;
  padding-bottom: 20px;
}

.inspection-form-demo-summary > table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 10px;
}

.inspection-form-demo-summary > div {
  margin-bottom: 15px; /* Increased margin */
}

.inspection-form-demo-summary span {
  margin-right: 10px; /* Increased margin */
  font-weight: bold; /* Bold text */
}

.score {
  background-color: #4caf50; /* or any other color you prefer */
  color: #fff;
  padding: 5px 10px; /* Increased padding */
  border-radius: 5px;
  margin-left: auto; /* Pull score to the right */
}
</style>
