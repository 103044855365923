var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-wrapper"},[(_vm.fieldForm.field_type == _vm.INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET)?_c('state-field-widget',{attrs:{"defaultValue":_vm.defaultValue,"defaultActionValue":_vm.defaultActionValue,"fieldForm":_vm.fieldForm,"disabled":_vm.disabled,"disableAction":_vm.disableAction,"commentValue":_vm.commentValue,"touched":_vm.touched},on:{"change":_vm.onChange,"actionChanged":_vm.onActionChanged}}):_vm._e(),(
      _vm.fieldForm.field_type == _vm.INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET
    )?_c('battery-state-field-widget',{attrs:{"defaultValue":_vm.defaultValue,"defaultActionValue":_vm.defaultActionValue,"fieldForm":_vm.fieldForm,"disabled":_vm.disabled,"disableAction":_vm.disableAction,"commentValue":_vm.commentValue,"touched":_vm.touched},on:{"change":(value) => {
        _vm.onChange(value);
        _vm.onActionChanged(_vm.INSPECTION_ACTION_NO_ACTION);
      }}}):(
      _vm.fieldForm.field_type == _vm.INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET
    )?_c('tire-state-field-widget',{attrs:{"defaultValue":_vm.defaultValue,"defaultActionValue":_vm.defaultActionValue,"fieldForm":_vm.fieldForm,"disabled":_vm.disabled,"disableAction":_vm.disableAction,"commentValue":_vm.commentValue,"touched":_vm.touched},on:{"change":_vm.onChange,"actionChanged":_vm.onActionChanged}}):(
      _vm.fieldForm.field_type == _vm.INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET
    )?_c('drum-thickness-field-widget',{attrs:{"defaultValue":_vm.defaultValue,"defaultActionValue":_vm.defaultActionValue,"fieldForm":_vm.fieldForm,"disabled":_vm.disabled,"disableAction":_vm.disableAction,"commentValue":_vm.commentValue,"touched":_vm.touched},on:{"change":_vm.onChange,"actionChanged":_vm.onActionChanged}}):(
      _vm.fieldForm.field_type == _vm.INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET
    )?_c('pad-thickness-field-widget',{attrs:{"defaultValue":_vm.defaultValue,"defaultActionValue":_vm.defaultActionValue,"fieldForm":_vm.fieldForm,"disabled":_vm.disabled,"disableAction":_vm.disableAction,"commentValue":_vm.commentValue},on:{"change":_vm.onChange,"actionChanged":_vm.onActionChanged}}):(_vm.fieldForm.field_type == _vm.INSPECTION_FORM_FIELD_TYPE_CHECK)?_c('check-field-widget',{attrs:{"defaultValue":_vm.defaultValue,"fieldForm":_vm.fieldForm,"disabled":_vm.disabled,"commentValue":_vm.commentValue},on:{"change":_vm.onChange}}):_c('default-field-widget',{attrs:{"fieldForm":_vm.fieldForm,"defaultValue":_vm.defaultValue,"defaultActionValue":_vm.defaultActionValue,"disabled":_vm.disabled,"disableAction":_vm.disableAction,"commentValue":_vm.commentValue},on:{"change":_vm.onChange,"actionChanged":_vm.onActionChanged}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }