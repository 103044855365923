<template>
  <base-switch
    v-model="fieldValue"
    :onText="$t('COMMON.YES')"
    :offText="$t('COMMON.NO')"
    :disabled="disabled"
  ></base-switch>
</template>

<script>
import { uniqueId, isEqual } from "lodash";

import { Button } from "element-ui";

export default {
  name: "check-field-widget",

  components: {
    [Button.name]: Button,
  },

  props: ["fieldForm", "defaultValue", "disabled"],

  data() {
    return {
      fieldValue: this.getFieldValue(this.defaultValue),
    };
  },

  computed: {},

  created() {},

  methods: {
    getFieldValue(value) {
      return value == "YES";
    },
  },

  mounted() {},

  watch: {
    fieldValue() {
      if (this.fieldValue != this.getFieldValue(this.defaultValue)) {
        this.$emit("change", this.fieldValue ? "YES" : "NO");
      }
    },

    defaultValue() {
      this.fieldValue = this.getFieldValue(this.defaultValue);
    },
  },
};
</script>
