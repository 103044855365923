<template>
  <div class="field-wrapper">
    <state-field-widget
      v-if="fieldForm.field_type == INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET"
      :defaultValue="defaultValue"
      :defaultActionValue="defaultActionValue"
      :fieldForm="fieldForm"
      :disabled="disabled"
      :disableAction="disableAction"
      :commentValue="commentValue"
      :touched="touched"
      @change="onChange"
      @actionChanged="onActionChanged"
    />
    <battery-state-field-widget
      v-if="
        fieldForm.field_type == INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET
      "
      :defaultValue="defaultValue"
      :defaultActionValue="defaultActionValue"
      :fieldForm="fieldForm"
      :disabled="disabled"
      :disableAction="disableAction"
      :commentValue="commentValue"
      :touched="touched"
      @change="
        (value) => {
          onChange(value);
          onActionChanged(INSPECTION_ACTION_NO_ACTION);
        }
      "
    />
    <tire-state-field-widget
      v-else-if="
        fieldForm.field_type == INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET
      "
      :defaultValue="defaultValue"
      :defaultActionValue="defaultActionValue"
      :fieldForm="fieldForm"
      :disabled="disabled"
      :disableAction="disableAction"
      :commentValue="commentValue"
      :touched="touched"
      @change="onChange"
      @actionChanged="onActionChanged"
    />
    <drum-thickness-field-widget
      v-else-if="
        fieldForm.field_type == INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET
      "
      :defaultValue="defaultValue"
      :defaultActionValue="defaultActionValue"
      :fieldForm="fieldForm"
      :disabled="disabled"
      :disableAction="disableAction"
      :commentValue="commentValue"
      :touched="touched"
      @change="onChange"
      @actionChanged="onActionChanged"
    />
    <pad-thickness-field-widget
      v-else-if="
        fieldForm.field_type == INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET
      "
      :defaultValue="defaultValue"
      :defaultActionValue="defaultActionValue"
      :fieldForm="fieldForm"
      :disabled="disabled"
      :disableAction="disableAction"
      :commentValue="commentValue"
      @change="onChange"
      @actionChanged="onActionChanged"
    />
    <check-field-widget
      v-else-if="fieldForm.field_type == INSPECTION_FORM_FIELD_TYPE_CHECK"
      :defaultValue="defaultValue"
      :fieldForm="fieldForm"
      :disabled="disabled"
      :commentValue="commentValue"
      @change="onChange"
    />
    <default-field-widget
      v-else
      :fieldForm="fieldForm"
      :defaultValue="defaultValue"
      :defaultActionValue="defaultActionValue"
      :disabled="disabled"
      :disableAction="disableAction"
      :commentValue="commentValue"
      @change="onChange"
      @actionChanged="onActionChanged"
    />
  </div>
</template>

<script>
import { isEqual } from "lodash";
import { Select, Option, Collapse, CollapseItem } from "element-ui";
import {
  INSPECTION_FORM_FIELD_TYPE_CHECK,
  INSPECTION_FORM_FIELD_TYPE_SELECT_SINGLE,
  INSPECTION_FORM_FIELD_TYPE_SELECT_MULTIPLE,
  INSPECTION_FORM_FIELD_TYPE_NUMBER,
  INSPECTION_FORM_FIELD_TYPE_TEXT,
  INSPECTION_FORM_FIELD_TYPE_RANGE_SLIDER,
  INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
} from "@/constants/inspectionForms";
import { INSPECTION_ACTION_NO_ACTION } from "@/constants/inspections";
import DefaultFieldWidget from "./DefaultFieldWidget.vue";
import StateFieldWidget from "./StateFieldWidget.vue";
import TireStateFieldWidget from "./TireStateFieldWidget.vue";
import BatteryStateFieldWidget from "./BatteryStateFieldWidget.vue";
import DrumThicknessFieldWidget from "./DrumThicknessFieldWidget.vue";
import PadThicknessFieldWidget from "./PadThicknessFieldWidget.vue";
import CheckFieldWidget from "./CheckFieldWidget.vue";

export default {
  name: "inspection-view-inspection-form-field-view",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    DefaultFieldWidget,
    StateFieldWidget,
    TireStateFieldWidget,
    BatteryStateFieldWidget,
    DrumThicknessFieldWidget,
    PadThicknessFieldWidget,
    CheckFieldWidget,
  },

  mixins: [],

  props: [
    "fieldForm",
    "defaultValue",
    "defaultActionValue",
    "disabled",
    "disableAction",
    "commentValue",
    "touched",
  ],

  data() {
    return {
      INSPECTION_FORM_FIELD_TYPE_CHECK,
      INSPECTION_FORM_FIELD_TYPE_SELECT_SINGLE,
      INSPECTION_FORM_FIELD_TYPE_SELECT_MULTIPLE,
      INSPECTION_FORM_FIELD_TYPE_NUMBER,
      INSPECTION_FORM_FIELD_TYPE_TEXT,
      INSPECTION_FORM_FIELD_TYPE_RANGE_SLIDER,
      INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET,
      INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
      INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET,
      INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
      INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
      fieldValue: null,
      fieldAction: null,
      fieldComment: null,
      INSPECTION_ACTION_NO_ACTION,
    };
  },

  computed: {},

  created() {},

  methods: {
    onChange(value) {
      if (!isEqual(value, this.fieldValue)) {
        this.fieldValue = value;
        this.$emit("change", this.fieldValue);
      }
    },

    onActionChanged(action, comment) {
      if (
        !isEqual(action, this.fieldAction) ||
        !isEqual(comment, this.fieldComment)
      ) {
        this.fieldAction = action;
        this.fieldComment = comment;
        this.$emit("actionChanged", action, comment);
      }
    },
  },

  mounted() {},

  watch: {
    defaultValue() {
      this.fieldValue = this.defaultValue;
    },
  },
};
</script>
